import React from "react"
import svc_1 from "../../images/services_icon_1.svg"
import svc_2 from "../../images/services_icon_2.svg"
import svc_3 from "../../images/services_icon_3.svg"
import svc_4 from "../../images/services_icon_4.svg"
import svc_5 from "../../images/services_icon_5.svg"
import svc_6 from "../../images/services_icon_6.svg"
import { Fade } from "react-awesome-reveal"
import { Link } from "gatsby"

export default class Services extends React.Component {
  render() {
    return (
      <div className="content-section">
        <Fade direction={"up"} triggerOnce={true} cascade={true}>
          <h2 className="heading-2">Solutions</h2>
        </Fade>
        <div className="container">
          <div className="w-layout-grid feature-grid">
            <Fade
              direction={"up"}
              triggerOnce={true}
              cascade={true}
              delay={400}
            >
              <div className="feature-card">
                <img src={svc_1} height="90" alt="" className="icon-3d" />
                <h3 className="heading-4">Architecture</h3>
                <p className="paragraph-3">
                  Our cloud architects build secure, high-performing, resilient,
                  and efficient infrastructure for your applications and
                  workloads.
                </p>
              </div>
            </Fade>
            <Fade
              direction={"up"}
              triggerOnce={true}
              cascade={true}
              delay={400}
            >
              <div className="feature-card">
                <img src={svc_6} height="90" alt="" className="icon-3d" />
                <h3>Migration</h3>
                <p className="paragraph-4">
                  Refactoring applications to be cloud native or simply lifting
                  and shifting, we've done it all.
                  <br />
                  Avoid the pitfalls with our custom tailored migration plans.
                </p>
              </div>
            </Fade>
            <Fade
              direction={"up"}
              triggerOnce={true}
              cascade={true}
              delay={400}
            >
              <div className="feature-card">
                <img src={svc_3} height="90" alt="" className="icon-3d" />
                <h3>DevOps</h3>
                <p className="paragraph-5">
                  Get your application out faster in a consistent fashion.
                  <br />
                  We help you build the perfect CI/CD pipeline and automate your
                  cloud footprint.
                </p>
              </div>
            </Fade>
            <Fade
              direction={"up"}
              triggerOnce={true}
              cascade={true}
              delay={400}
            >
              <div className="feature-card">
                <img src={svc_4} height="90" alt="" className="icon-3d" />
                <h3>Machine Learning</h3>
                <p className="paragraph-8">
                  From data preparation to production, get the help you need to
                  improve your business automation programs
                </p>
              </div>
            </Fade>
            <Fade
              direction={"up"}
              triggerOnce={true}
              cascade={true}
              delay={400}
            >
              <div className="feature-card">
                <img src={svc_5} height="90" alt="" className="icon-3d" />
                <h3>Security</h3>
                <p className="paragraph-6">
                  We help you build an infrastructure that is secure by design
                  and provide assessments to help you meet or exceed industry
                  standards.
                </p>
              </div>
            </Fade>
            <Fade
              direction={"up"}
              triggerOnce={true}
              cascade={true}
              delay={400}
            >
              <div className="feature-card">
                <img src={svc_2} height="90" alt="" className="icon-3d" />
                <h3>Cost Optimization</h3>
                <p className="paragraph-7">
                  We've helped companies save millions on their cloud footprint.
                  <br /> Let us assess your needs and provide a cost-optimized
                  plan that meets your requirements.
                </p>
              </div>
            </Fade>
          </div>
          <Fade direction={"up"} triggerOnce={true} cascade={true} delay={400}>
            <div>
              <Link
                to="/solutions"
                data-w-id="20e55e5c-6d8d-e297-0326-fb0d53503c38"
                className="button-wrapper-2 w-inline-block"
              >
                <div className="button-text-2">Explore our solutions</div>
                <div style={{ height: "0%" }} className="button-filler-one" />
                <div style={{ height: "0%" }} className="button-filler-two" />
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    )
  }
}
