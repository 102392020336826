import React from "react"
import bg_hero from "../../images/bg_hero.svg"
import hero from "../../images/hero.svg"
import { Fade } from "react-awesome-reveal"

export default class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <div className="container">
          <div className="w-layout-grid hero-grid-homepage">
            <div className="hero-block">
              <Fade direction={"up"} triggerOnce={true} cascade={true}>
                <h1 className="heading">
                  Your cloud, our expertise
                  <br /> Simple, fast, reliable
                  <br />
                </h1>
                <p className="paragraph-large left">
                  Whether you're only considering using the cloud or all-in,
                  we've got you covered at every step.
                  <br />
                </p>
              </Fade>
            </div>
            <Fade direction={"up"} triggerOnce={true} cascade={true}>
              <div className="hero-image-wrap">
                <img src={bg_hero} alt="" className="bg-hero" />
                <img src={hero} alt="" className="hero-image" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}
