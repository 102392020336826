import React from "react"
import Soon from "../../images/logos/soon.png"
import Spendlabs from "../../images/logos/Spendlabs.png"
import ni2 from "../../images/logos/Ni2.png"
import Acestar from "../../images/logos/Acestar.png"
import SalesLive from "../../images/logos/SalesLive.png"

import { Fade } from "react-awesome-reveal"

export default class Logos extends React.Component {
  render() {
    return (
      <Fade direction={"up"} triggerOnce={"true"}>
        <h2 className="heading-2">Meet the community</h2>
        <div className="logo-section">
          <div className="container">
            <div className="w-layout-grid logo-grid">
              <div className="logo-block">
                <a href={"https://soon.app"} target={"_blank"} rel="noreferrer">
                  <img src={Soon} alt="" />
                </a>
              </div>
              <div className="logo-block">
                <a
                  href={"https://www.spendlabs.com"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={Spendlabs} alt="" />
                </a>
              </div>
              <div className="logo-block">
                <a
                  href={"https://www.ni2designs.com/"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={ni2} alt="" />
                </a>
              </div>
              <div className="logo-block">
                <a
                  href={"http://acestar.my/"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={Acestar} alt="" />
                </a>
              </div>
              <div className="logo-block">
                <a
                  href={"https://sales.live/"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={SalesLive} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    )
  }
}
