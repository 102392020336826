import React from "react"
import Header from "../components/landing/Header"
import Approach from "../components/landing/Approach"
import Services from "../components/landing/Services"
import Logos from "../components/landing/Logos"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <Header />
      <Approach />
      <Services />
      <Logos />
    </Layout>
  )
}
