import React from "react"
import bg_icon_1 from "../../images/bg_icon_1.svg"
import bg_icon_2 from "../../images/bg_icon_2.svg"
import approach_1 from "../../images/our_approach_1.svg"
import approach_2 from "../../images/our_approach_2.svg"
import { Fade } from "react-awesome-reveal"

export default class Approach extends React.Component {
  render() {
    return (
      <div className="content-section">
        <div className="container">
          <div className="content">
            <Fade direction={"up"} cascade={true} triggerOnce={true}>
              <h2 className="heading-2">Our Expertise</h2>
              <p className="paragraph">
                <strong>
                  Certified cloud engineers ready to assist you with your
                  projects so that you can focus on growing your business.
                </strong>
                <br />
                <br />
                We'll help you develop your ideas and provide you with the tools
                needed to accomplish your goals with an emphasis on ease-of-use,
                scalability, automation, security and cost optimization.
              </p>
            </Fade>
          </div>
        </div>
        <div className="container-4 w-container">
          <img src={bg_icon_1} alt="" className="image-5" />
          <img src={bg_icon_2} alt="" className="image-6" />
          <div className="columns w-row">
            <Fade
              direction={"up"}
              triggerOnce={true}
              cascade={true}
              delay={200}
            >
              <div className="column w-col w-col-6">
                <div className="div-block-2">
                  <img src={approach_1} height="115" alt="" />
                  <h3 className="heading-3">SPOT</h3>
                  <p className="paragraph-2">
                    In a bind?
                    <br />
                    <br /> We'll provide short-term assistance to get you to the
                    finish line.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade
              direction={"up"}
              triggerOnce={"true"}
              delay={200}
              cascade={true}
            >
              <div className="w-col w-col-6">
                <div className="div-block-2">
                  <img src={approach_2} height="115" alt="" />
                  <h3 className="heading-3">RESERVED</h3>
                  <p className="paragraph-2">
                    New project or long-term evolutions?
                    <br />
                    <br />
                    Get a dedicated engineer to assist at every step with
                    defined deliverables.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}
